import React, { useEffect } from "react";
import { isEmpty } from "../../helpers/Functions";
import TextView from "../partials/Typography";
import { Box, List, ListItemText, Tooltip } from "@mui/material";
import ActionIconButton from "../partials/ActionIconButton";
import { useLayoutContext } from "./Layout";

const SubSidebar = ({
	onNavigationClick = () => { },
	onNavigationItemClick = () => { },
}) => {

	const {
		primaryOptions,
		navigationOptions,
		isSubSidebarOpen,
		setIsSubSidebarOpen,
		selectedPrimaryValue,
		uniqSelectedNavValue,
		selectedNavValue,
		selectedNavItemValue,
	} = useLayoutContext();

	useEffect(() => {
		setIsSubSidebarOpen(!isEmpty(navigationOptions?.[selectedPrimaryValue]));
	}, [selectedPrimaryValue]);

	return (
		<div className={`subsidebar-collapsible ${isSubSidebarOpen ? '' : 'close'}`}>
			<div className="subsidebar-collapsible-header">
				<div className="subsidebar-collapsible-header-title">
					<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M5 10L5.705 9.295L1.915 5.5H12V4.5H1.915L5.705 0.705L5 0L0 5L5 10Z" fill="white" />
					</svg>

					<TextView fontsize="xm" fontweight="bold">
						{primaryOptions?.find((primOpt) => primOpt.value === selectedPrimaryValue)?.title}
					</TextView>
				</div>
				<ActionIconButton
					customclasses="subsidebar-collapsible-header-collapse"
					label={"collapse"}
					onClick={() => {
						setIsSubSidebarOpen(!isSubSidebarOpen);
					}}
				/>
			</div>
			{navigationOptions?.[selectedPrimaryValue]?.map((cluster, i) => (
				<div className="subsidebar-collapsible-clusters" key={i}>
					{cluster?.items && (
						<>
							<TextView
								fontsize="primary"
								fontweight="semibold"
								customclasses={`subsidebar-collapsible-title ${cluster.interactive && 'interactive'} ${(cluster.value === uniqSelectedNavValue) && 'active'}`}
								onClick={() => {
									if (!cluster.interactive) {
										return;
									}

									onNavigationClick(cluster.value);
								}}
							>
								{cluster.title}
							</TextView>
							<Box className="subsidebar-collapsible-title-divider" />
							<List className="subsidebar-wide-options">
								{cluster?.items?.map((item, y) => (
									<Tooltip
										placement="right"
										arrow
										title={item?.tooltip}
										disableHoverListener={!item?.disabled}
									>
										<ListItemText
											key={y}
											className={`subsidebar-wide-options-item library-details-sidebar ${selectedNavValue === cluster.value && selectedNavItemValue === item.value ? 'active' : ''} ${item.disabled && 'disabled'}`}
											onClick={() => {
												if(item?.disabled) {
													return;
												}

												onNavigationItemClick(cluster?.value, item?.value);
											}}
										>
											<TextView fontsize="xm" fontweight="regular" customclasses={`subsidebar-wide-options-item-text library-details-sidebar ${selectedNavValue === cluster.value && selectedNavItemValue === item.value ? 'active' : ''}`}>
												{item.title}
											</TextView>
										</ListItemText>
									</Tooltip>
								))}
							</List>
						</>
					)}
				</div>
			))}
		</div>
	);
}

export default SubSidebar;