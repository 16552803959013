import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { checkIsActive } from "../helpers/RouterHelper";
import { DEFAULT_OLERN_OPTION_ICON, DEFAULT_OLERN_WIDE_IMAGE } from "../../models/Constants";
import TextView from "../partials/Typography";
import { Box, List, Tooltip } from "@mui/material";
import { Image } from "react-bootstrap";
import { useLayoutContext } from "./Layout";
import ActionIconButton from "../partials/ActionIconButton";
import { isEmpty } from "../../helpers/Functions";

const MainSidebar = ({
	onValueSelect = () => { },
}) => {
	const location = useLocation();

	const {
		primaryOptions,
		isMainSidebarOpen,
		selectedPrimaryValue,
		setIsMainSidebarOpen,
	} = useLayoutContext();

	const getMenuIsActive = (location, urls) => {
		if (Array.isArray(urls)) {
			for (let url of urls) {
				if (checkIsActive(location, url)) {
					return "active";
				}
			}
		}
		else {
			return checkIsActive(location, urls) ? "active" : "";
		}

		return "";
	}

	return (
		<div className={`main-sidebar-fixed ${!isMainSidebarOpen && 'close'}`}>
			<Link to="/" className="main-sidebar-logo-icon">
				<Image src={isMainSidebarOpen ? DEFAULT_OLERN_WIDE_IMAGE : DEFAULT_OLERN_OPTION_ICON} />
			</Link>
			<div className={`main-sidebar-container ${!isMainSidebarOpen && 'close'}`}>
				<List className="main-sidebar-container-items">
					<Box className={`subsidebar-wide-options-item sidebar-toggle ${!isMainSidebarOpen && 'close'}`}>
						<ActionIconButton
							icon={isMainSidebarOpen ? (
								<KeyboardDoubleArrowLeftIcon className="theme-sub-nav-icon" />
							) : (
								<KeyboardDoubleArrowRightIcon className="theme-sub-nav-icon" />
							)}
							onClick={() => {
								setIsMainSidebarOpen(!isMainSidebarOpen);
							}}
						/>
					</Box>
					{primaryOptions?.map((item, j) => {

						if (item?.hidden) {
							return null;
						}

						let isLink = !isEmpty(item?.url);
						let isActive = isLink ? getMenuIsActive(location, item.url) : item.value === selectedPrimaryValue;
						let OptionContainer = Box;

						if (item?.url) {
							OptionContainer = Link;
						}

						let option = (
							<OptionContainer
								to={Array.isArray(item?.url) ? item?.url?.[0] : item?.url}
								className={`subsidebar-wide-options-item ${!isMainSidebarOpen && 'close'} ${(isActive) && 'active'}`}
								onClick={() => {
									let value = item?.value;

									onValueSelect(value);
								}}
							>
								{item.icon}
								<TextView fontsize="secondary" fontweight="regular" customclasses={`subsidebar-wide-options-item-text ${!isMainSidebarOpen && 'close'}`}>{item.title}</TextView>
							</OptionContainer>
						);

						if (!isMainSidebarOpen) {
							option = (
								<Tooltip
									placement="right"
									title={item.title}
									arrow
									key={j}
								>
									{option}
								</Tooltip>
							);
						}

						return (
							<React.Fragment key={j}>
								{option}
								{item.line_break && <Box className="main-sidebar-divider" />}
							</React.Fragment>
						);
					})}
				</List>
			</div>
		</div>
	);
}


const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities
	}

	return data;
}

export default connect(mapStateToProps)(MainSidebar);