import React, { useEffect, useState } from "react";

import { 
  Typography,
} from '@mui/material';

const CountdownTimer = React.memo(({ scheduled_date, start_time }) => {
  const calculateTimeLeft = () => {
    let now = new Date();
    let schedDate = (scheduled_date ?? "") + " " + start_time;
    let dateNow = formatDate(now.toLocaleString('en-US', {timeZone: "Asia/Manila"}));

    const difference = +new Date(schedDate) - +new Date(dateNow);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes(),
        second = '' + d.getSeconds();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if (hour.length < 2) 
        hour = '0' + hour;
    if (minute.length < 2) 
        minute = '0' + minute;
    if (second.length < 2) 
        second = '0' + second;

    if (hour === 0)
        hour = '00';
    if (minute === 0)
        minute = '00';
    if (second === 0)
        second = '00';

    return [year, month, day].join('-') + " " + [hour, minute, second].join(':');
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <div
        key={timeLeft + interval}
        className="rounded mx-3 d-flex align-items-center"
        style={{
          backgroundColor: "rgba(255,255,255,0.5)",
          height: "13vh",
          width: "13vh",
        }}
      >
        <div className="w-100">
          <Typography variant="h3" align="center" className="text-dark">
            <b>{timeLeft[interval]} </b>
          </Typography>
          <Typography variant="h6" align="center" className="text-dark">
            {interval}
          </Typography>
        </div>
      </div>
    );
  });

  return (
    <div className="d-flex w-100 justify-content-center">
      {timerComponents.length ? timerComponents : <Typography variant="h4" align="center" className="text-white">This event is starting soon.</Typography>}
    </div>
  );
})

export default CountdownTimer;