import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";
import { DEFAULT_AUTH_HEADER } from "../../models/Constants";

export function getOwnerLibraries(params, config={}) {
	return axios.get(process.env.REACT_APP_API_URL + "/library/get-owner-libraries-list?" + objectToUrlQuery(params), config);
}

export function getLibrary(params, config={}) {
	return axios.get(process.env.REACT_APP_API_URL + "/library?" + objectToUrlQuery(params), DEFAULT_AUTH_HEADER, config);
}

export function getStudentLibraries(params, config={}) {
	return axios.get(process.env.REACT_APP_API_URL + "/library/get-student-libraries-list?" + objectToUrlQuery(params), config);
}

export function createLibrary(params) {
	let dataPost = new FormData();

	for (const [key, value] of Object.entries(params)) {
		dataPost.append(key, value);
	}

	return axios.post(process.env.REACT_APP_API_URL + "/library/create-owner-library", dataPost);
}

export function updateLibrary(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/library/update-owner-library-details", params);
}

export function updateOwnerLibrarySettings(library_party_id, values, is_mentor = 0){
	return axios.put(
		process.env.REACT_APP_API_URL 
		+ "/library/update-owner-library-settings/"
		+ library_party_id
		+ `?is_mentor=${is_mentor}`,
		values
	);
}

export function updateLibraryPaymentDetails(library_party_id, values){
	return axios.post(process.env.REACT_APP_API_URL + "/library/update-library-payment-details/" + library_party_id, values);
}

export function getLibraryDetails(library_party_id, params={}) {
	return axios.get(process.env.REACT_APP_API_URL + `/library/get-owner-library-details/${library_party_id}?` + objectToUrlQuery(params));
}

export function getLibraryContent(params={}, config={}) {
	return axios.get(process.env.REACT_APP_API_URL + "/library/get-owner-library-content?" + objectToUrlQuery(params), config);
}

export function updateOwnerLibrary(params) {
	let dataPost = new FormData();

	for (const [key, value] of Object.entries(params)) {
		if (typeof value === 'object') {
			if (value instanceof File) { // Check if the value is a file object
				dataPost.append(key, value, value.name); // Append the file object with its name
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		} 
		else {
			dataPost.append(key, value);
		}
	}

	// for (let [key, value] of dataPost.entries()) {
	// 	console.log(key, value);
	// }
	
	return axios.post(process.env.REACT_APP_API_URL + "/library/update-owner-library", dataPost);
}

export function getEnrollees(libraryID, params = {}, config = {}) {
	return axios.get(process.env.REACT_APP_API_URL + `/library/${libraryID}/enrollees?` + objectToUrlQuery(params), config);
}

export function enrollLibraryStudent(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/library/create-owner-enrollee", params);
}

export function approveStudentLibraryAccess(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/library/approve-library-access", params)
}

export function approveStudentLibraryAccessEmail(params, path) {
	return axios.post(process.env.REACT_APP_API_URL + "/approve-library-access-from-email/" + path, params, DEFAULT_AUTH_HEADER)
}

export function revokeStudentLibraryAccess(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/library/revoke-library-access", params)
}

export function removeLibraryMentor(params, library_id){
	return axios.post(process.env.REACT_APP_API_URL + "/library/delete-owner-library-mentor/" + library_id, params)
}

export function enrolleeExport(library_id) {
	return axios.get(
		process.env.REACT_APP_API_URL + "/library/export-library-enrollee/" + library_id, 
		{ responseType: 'blob',}
	)
}

export function enrolleeImport(params){
	let dataPost = new FormData();

	for (const [key, value] of Object.entries(params)) {
		if (typeof value === 'object') {
			if (value instanceof File) { // Check if the value is a file object
				dataPost.append(key, value, value.name); // Append the file object with its name
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		} 
		else {
			dataPost.append(key, value);
		}
	}

	return axios.post(process.env.REACT_APP_API_URL + "/library/import-owner-enrollee", dataPost)
}

export function publishOwnerLibrary(params, libraryID){
	return axios.put(process.env.REACT_APP_API_URL + "/library/publish-owner-library/" + libraryID, params);
}

export function getLibraryList(companyID) {
	return axios.get(process.env.REACT_APP_API_URL + "/library/get-library-list/" + companyID);
}

export function createWPProduct(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/library/create-wp-product", params);
}

export function getElementsSummary(libraryCode){
	return axios.get(process.env.REACT_APP_API_URL + "/library-elements/count?search_by_code=1&code=" + libraryCode, DEFAULT_AUTH_HEADER);
}

export function getLibraryStudents(libraryID, params) {
	return axios.get(process.env.REACT_APP_API_URL + `/library/${libraryID}/students?` + objectToUrlQuery(params));
}

export function getLibraryMentors(libraryID, params = {}, config = {}) {
	return axios.get(process.env.REACT_APP_API_URL + `/library/${libraryID}/mentors?` + objectToUrlQuery(params), config);
}

export function saveMentorLibrary(params) {
	return axios.post(process.env.REACT_APP_API_URL + '/library/mentors', params);
}

export function getLibraryPricePoints() {
	return axios.get(process.env.REACT_APP_API_URL + '/library/get-price-points');
}

export function getMentorLibraries(params, config={}) {
	return axios.get(process.env.REACT_APP_API_URL + '/library/get-mentor-libraries-list?' + objectToUrlQuery(params), config);
}

export function searchLibrary(params) {
	return axios.get(process.env.REACT_APP_API_URL + '/library/search?' + objectToUrlQuery(params));
}

export function getStudentLibraryContent(library_id, config={}) {
	return axios.get(process.env.REACT_APP_API_URL + '/library/get-student-library-content/' + library_id, config);
}

export function getLibraryStats(params) {
	return axios.get(process.env.REACT_APP_API_URL + '/library/stats?' + objectToUrlQuery(params));
}

export function archiveLibrary(params) {
	return axios.patch(process.env.REACT_APP_API_URL + '/library/archive', params);
}

export function getLibraryCompanies(libraryID, params={}) {
	return axios.get(process.env.REACT_APP_API_URL + `/library/${libraryID}/companies?` + objectToUrlQuery(params));
}

export function getLibraryEnrolleesStats(libraryID) {
	return axios.get(process.env.REACT_APP_API_URL + `/library/${libraryID}/enrollees/stats`);
}

export function addLibraryMentor(libraryID, params) {
	return axios.post(process.env.REACT_APP_API_URL + `/library/${libraryID}/mentors`, params);
}

export function getWebinarSettings(libraryID) {
	return axios.get(process.env.REACT_APP_API_URL + `/webinar/webinar-settings/${libraryID}`);
}

export function updateWebinarSettings(params) {
	let dataPost = new FormData();

	for(const [key, value] of Object.entries(params)) {
		if (value instanceof File) {
			dataPost.append(key, value, value.name);
		} 
		else {
			dataPost.append(key, JSON.stringify(value));
		}
	}

	return axios.post(process.env.REACT_APP_API_URL + `/webinar/webinar-settings`, dataPost);
}

export function sendWebinarInvite(params) {
	return axios.post(process.env.REACT_APP_API_URL + `/webinar/send-webinar-invite`, params);
}

export function checkWebinarStudent(library_code, user_code) {
	return axios.get(process.env.REACT_APP_API_URL + `/check-webinar-user?library_code=${library_code}&webinar_code=${user_code}`, DEFAULT_AUTH_HEADER);
}

export function startWebinarEvent(params) {
	return axios.post(process.env.REACT_APP_API_URL + `/webinar/start`, params);
}