import React, { useEffect, useState } from "react";
import AvatarDropDown from "../../partials/user_profile/AvatarDropDown";
import { isEmpty } from "../../../helpers/Functions";
import { DIR_USER_PROFILE } from "../../../models/Constants";
import { Image } from "react-bootstrap";

const Avatar = ({ ...props }) => {
	const [avatar, setAvatar] = useState(null);

	useEffect(() => {
		if (!isEmpty(props.userdetail.firstname)) {
			let initials = props.userdetail.firstname.slice(0, 1) + props.userdetail.lastname.slice(0, 1);

			setAvatar(initials.toUpperCase());
			props.setUpdate(true)
		}
	}, [props.userdetail.firstname, props.userdetail.lastname]);


	return (
		<div className="theme-header-profile-picture">
			<div className="theme-header-initials" >
				{	
					(!isEmpty(props.userdetail.user_img)) ?  
					<Image 
						className="d-block w-100 h-100"
						src={process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + props.userdetail.user_img}
						style={{
							objectFit: "cover",
						}}
					/> : 
					avatar
				}
			</div>
		</div>
	)
}

export default Avatar;