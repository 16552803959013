import React, { useEffect, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { useLocation } from "react-router-dom";
import { isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import { connect } from "react-redux";
import { getRatingsResult, quickSubmitElementResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import BaseTextField from "../../BaseTextField";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { Button } from "react-bootstrap";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const RatingsContent = ({ ratings, ...props}) => {
	const submitResponse = () => {
		const hasUnfinishedRatings = props.answer.some(answer => {
			if (answer.value == 0) {
				displayToast("info", "Please complete the ratings");
				return true;
			}
			return false;
		});
	
		if (hasUnfinishedRatings) {
			return;
		}
		else {
			props.submitStudentResponse();
		}
	}

	return(<>
		{
			(!isNotStudent(props?.userCapabilities?.role) && props.answerSent) ? (<>
				<center>
					<div className="d-block mb-2 py-3 px-1">
						<TextView className="d-block font-size-xl">
							Thank you for your valuable feedback.
						</TextView>
						<TextView className="d-block font-size-medium color-secondary">
							We sincerely appreciate your ratings and comments which will help us improve and make things better. Your inputs truly matter to us.
						</TextView>
					</div>
				</center>
			</>) : (<>
				<div className="d-block mb-4">
					<TextView fontsize="regular">
						{ props.element?.description }
					</TextView>
				</div>

				{
					isNotStudent(props?.userCapabilities?.role) ? (
						<ResultContainer 
							ratingcontents={props.ratingcontents}
							ratings={ratings}
						/>
					) : (
						<React.Fragment>
							<div className="mb-3">
								{
									props.element.additional_attachments.map((criteria, i) => (
										<div className="d-block mb-2" key={i}>
											<TextView className="mb-1">{criteria?.description}</TextView>
											<div className="ms-2 ps-3 radio-button-group">
											{ratings.map((rating) => (
												<div key={rating.value} className="mb-1 me-3 d-flex">
													<input
														type="radio"
														id={`rating-${i}-${rating.value}`}
														name={`rating-${i}`}
														value={rating.value}
														checked={props.answer?.[i]?.value === rating.value}
														onChange={(e) => {
															let newAnswers = [...props.answer];
															let ratingIndex = { ...newAnswers[i] };

															ratingIndex.value = parseInt(e.target.value);
															newAnswers[i] = ratingIndex;
															props.setAnswer(newAnswers);
														}}
														className="form-check-input me-2"
													/>
													<TextView htmlFor={`rating-${i}-${rating.value}`} className="form-check-label color-secondary">
														{rating.label}
													</TextView>
												</div>
											))}
											</div>
										</div>
									))
								}
							</div>
							
							{props.element?.context?.require_explanation && (<>
								{
									(Array.isArray(props.element.context.explanation_description) ?
										props.element.context.explanation_description : 
										[props.element.context.explanation_description]
									).map((item, index) => (
										<div className="mb-2">
											<div className="d-flex mb-1">
												<TextView className="font-size-medium color-septenary">{item}</TextView>
											</div>
											<BaseTextField
												key={index} // Ensure each component has a unique key
												customclasses="mb-3 mt-1"
												placeholder="Please explain your answer"
												value={props.explanation[index]}
												multiline
												minRows={2}
												maxRows={2}
												rows={2}
												onChange={(e) => {
													let newArr = [...props.explanation];
													newArr[index] = e.target.value;
													props.setExplanation(newArr);
												}}
											/>
										</div>
									))
								}
							</>)}

							<BaseButton 
								variant={isEmpty(props.quickAccess) ? (isNotStudent(props.userCapabilities.role) ? "green" : "primary") : "primary"}
								customclasses="d-block ms-auto my-4"
								onClick={() => {
									submitResponse();
								}}
							>
								Submit
							</BaseButton>
						</React.Fragment>
					)
				}	
			</>)
		}

		{
			(!isNotStudent(props?.userCapabilities?.role) && props.answerSent && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, props.studentProgress);
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const ResultDialog = ({ ...props }) => {

	return (
		<Dialog 
			open={props.open} 
			fullWidth
			maxWidth="md"
		>
			<DialogTitle className="font-size-large">Ratings Results</DialogTitle>
			<Divider />
			<DialogContent style={{ whiteSpace: 'pre-line' }}>
				<ResultContainer 
					ratingcontents={props.content}
					ratings={props.ratings}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.onClose?.(false)
					}}
					color="primary"
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const ResultContainer = ({ ratingcontents, ratings }) => {
	const [expIndex , setExpIndex] = useState(0);

	const calculateRating = (number) =>{
		const roundedNumber = Math.round(number);
		const rating = ratings.find(r => r.value === roundedNumber);
		
		return rating ? rating.label : "Invalid rating";
	}

	return (
		<div className="w-100">
			{
				!isEmpty(ratingcontents.results) ? (<>
					<div className="w-100">
						<TextView customclasses="d-block mb-2">Total Responses: {ratingcontents.results[0].responses}</TextView>
						<div className="px-2">
							{
								ratingcontents.results.map(result => (
									<div className="d-block mb-2">
										<TextView fontcolor="primary">{result.description} :</TextView>
										<TextView customclasses="d-block ms-3">{calculateRating(result.average)} ( {result.average} / 5 )</TextView>
									</div>
								))
							}
						</div>
					</div>

					<div className="mt-4">
						<div className="px-2">
							{
								!isEmpty(ratingcontents.explanations) ? (<>
									<div className="mb-2">
										<div className="d-block mb-2">
											<TextView className="d-block color-primary">{ratingcontents.explanations[expIndex]?.name} :</TextView>
											<TextView className="d-block color-black ms-3">Question: {ratingcontents.explanations[expIndex]?.question}</TextView>
											<TextView className="d-block color-secondary ms-3">{ratingcontents.explanations[expIndex]?.explanation}</TextView>
										</div>
										<div className="d-flex">
											{
												expIndex > 0 ? (
													<KeyboardDoubleArrowLeftIcon
														className='font-size-xl color-primary me-auto'
														style={{marginTop: '0.75rem', cursor: 'pointer'}}
														onClick={() => {setExpIndex(expIndex - 1)}}
													/>
												) : null
											}
											{
												expIndex < ratingcontents.explanations.length - 1 ? (
													<KeyboardDoubleArrowRightIcon
														className='font-size-xl color-primary ms-auto'
														style={{marginTop: '0.75rem', cursor: 'pointer'}}
														onClick={() => {setExpIndex(expIndex + 1)}}
													/>
												) : null
											}
										</div>
									</div>
								</>) : (<TextView>No Comments has been Found.</TextView>)
							}
						</div>
					</div>
				</>) :
				(<TextView>No Responses Yet. Please be patient.</TextView>)
			}
		</div>
	)
}

const RatingsElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);
	const [answerSent, setAnswerSent] = useState(false)
	const [ratingsAnswers, setRatingAnswers] = useState([]);
	const [explanation, setExplanation] = useState([])

	const ratings = [
		{ "value": 1, "label": "Inadequate" },
		{ "value": 2, "label": "Fair" },
		{ "value": 3, "label": "Good" },
		{ "value": 4, "label": "Very Good" },
		{ "value": 5, "label": "Excellent" }
	]

	useEffect(() => {
		let answers = []

		props.element.additional_attachments.map(criteria => {
			answers.push({"id" : criteria.id, "value" : 0})
		})

		setRatingAnswers(answers);
		setAnswerSent(props?.element?.student_element_progress?.is_completed == 1);
	}, [props.element])

	useEffect(() => {
		if(typeof props.element?.context?.explanation_description == "string") {
			props.element.context.explanation_description = [props.element?.context?.explanation_description]
		}
	}, [props.element])

	const submitStudentResponse = () => {
		const student_response = {
			context : {
				require_explanation : props.element?.context?.require_explanation,
				explanation_description : props.element?.context?.explanation_description,
				explanation_answer : explanation
			},
			answers: ratingsAnswers
		}

		let params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element?.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props?.element?.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: student_response
		}

		if(isEmpty(props.currentCapabilities.role) && !isEmpty(props.quickAccess)){
			params.email = props.quickAccess.email;
			params.firstname = props.quickAccess.name.firstname;
			params.lastname = props.quickAccess.name.lastname;

			quickSubmitElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Answer Submitted");
					setAnswerSent(true)
				}
			})
		}
		else if(!isNotStudent(props.currentCapabilities.role)) {
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Answer Submitted");
					setAnswerSent(true)
				}
			})
		}
	}

	const [ratingsResult, setRatingsResult] = useState({});
	const [openResults, setOpenResults] = useState(false);

	const getElementRatingsResult = () => {
		getRatingsResult(props.element.element_id).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				setRatingsResult(response.data.data[0]);
			}
		})
	}

	useEffect(() => {
		if(isNotStudent(props.currentCapabilities.role)) {
			getElementRatingsResult();
		}
	}, []);

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<ResultDialog
					element={props.element}
					open={openResults}
					onClose={setOpenResults}
					content={ratingsResult}
					ratings={ratings}
				/>
				<RatingsContent 
					ratings={ratings}
					element={props.element}
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
					answer={ratingsAnswers}
					answerSent={answerSent}
					explanation={explanation}
					setAnswer={setRatingAnswers}
					setExplanation={setExplanation}
					submitStudentResponse={submitStudentResponse}
					getRatingsResult={getElementRatingsResult}
					ratingcontents={ratingsResult}
				/>
			</LessonAccordion>
		) : (
			<RatingsContent 
				ratings={ratings}
				element={props.element}
				userCapabilities={props.currentCapabilities}
				answer={ratingsAnswers}
				answerSent={answerSent}
				explanation={explanation}
				setAnswer={setRatingAnswers}
				setExplanation={setExplanation}
				submitStudentResponse={submitStudentResponse}
				quickAccess={props.quickAccess}
				ratingcontents={ratingsResult}
			/>
		)
		
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		quickAccess: state.quickAccess
	}

	return data;
}

export default connect(mapStateToProps)(RatingsElement);